import { withDependencies } from '@wix/thunderbolt-ioc'
import { HeadContentType, IHeadContent } from '@wix/thunderbolt-symbols'

type HeadContentFactory = () => IHeadContent

const headContent: HeadContentFactory = () => {
	let headSEO: string = ''
	const headOther: Array<string> = []

	return {
		setHead: (content: string, type?: HeadContentType) => {
			switch (type) {
				case HeadContentType.SEO:
					headSEO = content
					break
				default:
					headOther.push(content)
					break
			}
		},

		getHead: () => {
			return [headSEO, ...headOther].join('\n')
		},
	}
}

export const HeadContent = withDependencies([], headContent)
